<template>
	<div class="page">
		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small" label-width="128px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.payed_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="id搜索">
				<el-input class="el_input" v-model="form.id" placeholder="id搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="公司名称">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="公司名称搜索"
					clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="车牌号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付类型">
				<el-select class="el_input" v-model="form.this_pay_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="现金" value="1"></el-option>
					<el-option label="燃油费" value="2"></el-option>
					<el-option label="过路费" value="3"></el-option>
					<el-option label="垫资支付" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.pay_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="2"></el-option>
					<el-option label="支付完成" value="3"></el-option>
					<el-option label="失败/打回" value="4"></el-option>
					<el-option label="支付撤销" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="申请中" value="2"></el-option>
					<el-option label="已开出" value="3"></el-option>
					<el-option label="已驳回" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settle_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待打款" value="2"></el-option>
					<el-option label="已打款" value="3"></el-option>
					<el-option label="结算失败" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="绑卡状态">
				<el-select class="el_input" v-model="form.bind_bankcard_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未绑卡" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机出款流水号">
				<el-input class="el_input" v-model="form.group_num_driver" placeholder="司机出款流水号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人出款流水号">
				<el-input class="el_input" v-model="form.group_num_truck_owner" placeholder="收款人出款流水号搜索"
					clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="支付时间">
				<div class="block">
					<el-date-picker v-model="date_value" type="datetimerange" :picker-options="pickerOptions"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
						value-format="timestamp">
					</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="结算时间">
				<div class="block">
					<el-date-picker v-model="settled_value" type="datetimerange" :picker-options="pickerOptions"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
						value-format="timestamp">
					</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item class="el_form_item" label="光大出款">
				<el-select class="el_input" v-model="form.out_cash_failed" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="光大出款失败" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否垫资">
				<el-select class="el_input" v-model="form.is_yufu" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回款">
				<el-select class="el_input" v-model="form.is_admin_pay_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_form_open">更多条件</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="tord_export_open">导出搜索结果</el-button>
				<!-- <el-button type="primary" @click="hsbank_pay_batch(0)">徽商批量打款</el-button> -->
				<!-- <el-button type="primary" @click="hsbank_ser_batch(0)">徽商批量查询</el-button> -->
				<el-button type="primary" @click="hsbank_settle_open()">批量人工打款</el-button>
				<el-button type="primary" @click="hsbank_download_receipt()">批量下载回单</el-button>
				<el-button type="primary" @click="hs_accelerate_payment(0)">批量加速打款</el-button>
				<el-button type="primary" @click="get_auto_outcash_set">{{is_outcash==1?"自动出款已开启":'自动出款未开启'}}</el-button>
				<el-button type="primary" @click="tord_pay_return_admin_open()">批量回款支付</el-button>
				<el-button type="primary" @click="tord_pay_return_admin_cancel()">批量撤销回款</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info">
			<a href="#bottom" id="haed">回到底部</a>
			<span>共:{{ list.total }}条</span>
			<span>&nbsp;&nbsp;&nbsp;司机 : {{ parseFloat(total.total_driver).toFixed(2) }}元&nbsp;&nbsp;&nbsp;</span>
			<span>&nbsp;&nbsp;&nbsp;收款人 : {{ parseFloat(total.total_truck_owner).toFixed(2) }}元&nbsp;&nbsp;&nbsp;</span>
			<span>&nbsp;&nbsp;&nbsp;总出款 :
				{{ (parseFloat(total.total_driver)+parseFloat(total.total_truck_owner)).toFixed(2) }}元&nbsp;&nbsp;&nbsp;</span>
			<span>&nbsp;&nbsp;&nbsp;总服务费 :
				{{ parseFloat(total.total_invoice-total.total_driver-total.total_truck_owner).toFixed(2) }}元&nbsp;&nbsp;&nbsp;</span>
			<span>&nbsp;&nbsp;&nbsp;总计 : {{ parseFloat(total.total_invoice).toFixed(2) }}元&nbsp;&nbsp;&nbsp;</span>
		</div>
		<!-- 表格 -->
		<div class="table">
			<div class="thead">
				<el-checkbox style="margin:10px 13px" @change="payed_all_choose"></el-checkbox>
				<!-- <el-button-group style="margin: -4.5px 0 0 0px;">
					<el-button size="mini" type="danger" @click="tords_settle_refuse">选中驳回结算</el-button>
				</el-button-group> -->
				<el-button-group style="margin: -4.5px 0 0 10px;">
					<el-button size="mini" type="danger"
						@click="tords_settle_revoke('truck_owner',0)">批量撤回车队长结算</el-button>
					<el-button size="mini" type="danger" @click="tords_settle_revoke('driver',0)">批量撤回司机结算</el-button>
				</el-button-group>
			</div>
			<div class="tbody">
				<div class="tr" v-for="(tord_item,tord_index) in list.data" :key="tord_index">
					<div class="tord_info">
						<div class="item" style="width:180px">
							<div class="top">运单:{{tord_item.truck_tord_num}}</div>
							<div class="bottom">建单:{{tord_item.creat_time_text}}</div>
						</div>
						<div class="item" style="flex: 1;">
							<div class="top">
								发货地:{{tord_item.case_prov}}/{{tord_item.case_city}}/{{tord_item.case_county}}</div>
							<div class="bottom">{{tord_item.case_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="flex: 1;">
							<div class="top">到货地:{{tord_item.aim_prov}}/{{tord_item.aim_city}}/{{tord_item.aim_county}}
							</div>
							<div class="bottom">{{tord_item.aim_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">货物:{{tord_item.cargo_type}}/{{tord_item.cargo_name}}</div>
							<div class="bottom">{{tord_item.cargo_weight}}吨/{{tord_item.cargo_volume}}方</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">总运费:{{tord_item.cost_info.freight_total}}元</div>
							<div class="bottom">已支付:{{tord_item.cost_info.payed_total_of_all}}元</div>
						</div>
						<div class="item" style="width:230px">
							<div class="top">货主:{{tord_item.shipper_info.name}}/{{tord_item.shipper_info.tel}}</div>
							<div class="bottom">{{tord_item.shipper_company_info.name}}</div>
						</div>
						<div class="item" style="width:150px">
							<div class="top">收款人:{{tord_item.truck_owner_info.name}}/{{tord_item.truck_owner_info.tel}}
							</div>
							<div class="bottom">司机:{{tord_item.driver_info.name}}/{{tord_item.driver_info.tel}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">车辆:{{tord_item.truck_plate_num}}</div>
						</div>
						
					</div>
					<div class="payed_list">
						<div class="ptr" v-for="(payed_item,payed_index) in tord_item.payed_list" :key="payed_index">
							<div class="ptd">
								<el-checkbox v-model="payed_num_choosed[payed_item.payed_num].checked"></el-checkbox>
							</div>

							<div class="ptd" style="width:300px">
								<div class="top">id:{{payed_item.id}}</div>
								<div>{{payed_item.payed_num}}</div>
								<div>{{payed_item.creat_time_text}}</div>
							</div>

							<div class="ptd" style="width:300px;">
								<div>[支付]:{{payed_item.this_payed_text}}/服务费:{{payed_item.service_charge_text}}</div>
								<div>状态:{{payed_item.pay_status_text}}</div>
							</div>

							<div class="ptd" style="width:200px;">
								<div>[发票]:{{payed_item.this_invoiced}}元</div>
								<div>状态:{{payed_item.invoice_status_text}}</div>
							</div>

							<div class="ptd" style="width:300px" v-if="payed_item.this_settled_of_truck_owner>0">
								<div>
									[收款人收款]:{{payed_item.this_settled_of_truck_owner}}元/类型:{{payed_item.this_pay_type_text}}
								</div>
								<div style="display:flex;" v-if="payed_item.this_settled_of_truck_owner>0">
									<div>状态:{{payed_item.settle_status_of_truck_owner_text}}</div>
									<div style="margin-left:5px">
										<div v-if="payed_item.settle_status_of_truck_owner==2" class="btn blue"
											@click="settle_open(tord_index,payed_index,'truck_owner')">结算</div>
										<!-- <div v-if="payed_item.settle_status_of_truck_owner==2" class="btn red" @click="settle_refuse(payed_item,'truck_owner')"  style="color:red">驳回</div> -->
										<div v-if="user_info.ug_info.name == '开发者'|| user_info.ug_info.name =='财务部门'">
											<div class="btn red" @click="settle_revoke(payed_item,'truck_owner')"
												style="color:red">撤销到未支付</div>
										</div>

										<div v-if="payed_item.settle_status_of_truck_owner==3" class="btn blue"
											@click="see_receipt(payed_item,'truck_owner')">查看回单</div>
										<!-- <div v-if="payed_item.settle_status_of_truck_owner==4" class="btn blue" @click="settle_open(tord_index,payed_index,'truck_owner')" >重启结算</div> -->
									</div>
								</div>
								<div>
									{{payed_item.truck_owner_bank_list.num==0?'收款人信息为空':(payed_item.truck_owner_bank_list.num!=1?"收款人绑定多张卡":'') }}
								</div>
								<div v-if="payed_item.truck_owner_bank_list.num==1">
									<div>收款人:{{payed_item.truck_owner_bank_list_info.card_user_name}}</div>
									<div>收款银行:{{payed_item.truck_owner_bank_list_info.bank_cname}}</div>
									<div>收款卡号:{{payed_item.truck_owner_bank_list_info.card_num}}</div>
									<div v-if="payed_item.settle_status_of_truck_owner !=3 && payed_item.text_api">
										打款状态:{{payed_item.text_api}}[<div class="btn blue"
											@click="cash_settle_ser_by_hsbank(payed_item,'truck_owner')">查询</div>]</div>
									<div>流水编号:{{payed_item.api_running_num_truck_owner}}</div>
								</div>
							</div>
							<div class="ptd" style="width:280px" v-if="payed_item.this_settled_of_driver>0">
								<div>[司机收款]:{{payed_item.this_settled_of_driver}}元/类型:{{payed_item.this_pay_type_text}}
								</div>
								<div v-if="payed_item.this_pay_type==4" >回款:{{payed_item.admin_pay_return}}/{{payed_item.this_settled_of_driver}}</div>
								
								<div style="display:flex;" v-if="payed_item.this_settled_of_driver>0">
									<div>状态:{{payed_item.settle_status_of_driver_text}}</div>
									<div style="margin-left:5px">
										<div v-if="payed_item.settle_status_of_driver==2" class="btn blue"
											@click="settle_open(tord_index,payed_index,'driver')">结算</div>
										<!-- <div v-if="payed_item.settle_status_of_driver==2" class="btn red" @click="settle_refuse(payed_item,'driver')"  style="color:red">驳回</div> -->
										<div v-if="user_info.ug_info.name == '开发者'|| user_info.ug_info.name =='财务部门'">
											<div class="btn red" @click="settle_revoke(payed_item,'driver')"
												style="color:red">撤销到未支付</div>
										</div>

										<div v-if="payed_item.settle_status_of_driver==3" class="btn blue"
											@click="see_receipt(payed_item,'driver')">查看回单</div>
										<div v-if="payed_item.settle_status_of_driver==4" class="btn blue"
											@click="settle_open1(payed_item)">重启结算</div>
										<!-- <div v-if="payed_item.settle_status_of_driver==4" class="btn blue" @click="settle_open(tord_index,payed_index,'driver')" >重启结算</div> -->
									</div>
								</div>
								<div>
									{{payed_item.driver_bank_list.num==0?'收款人信息为空':(payed_item.driver_bank_list.num!=1?"收款人绑定多张卡":'') }}
								</div>
								<div v-if="payed_item.driver_bank_list.num==1">
									<div>收款人:{{payed_item.driver_bank_list_info.card_user_name}}</div>
									<div>收款银行:{{payed_item.driver_bank_list_info.bank_cname}}</div>
									<div>收款卡号:{{payed_item.driver_bank_list_info.card_num}}</div>
									<div v-if="payed_item.settle_status_of_driver !=3 && payed_item.text_api">
										打款状态:{{payed_item.text_api}}[<div class="btn blue"
											@click="cash_settle_ser_by_hsbank(payed_item,'driver')">查询</div>]</div>
									<div>流水编号:{{payed_item.api_running_num}}</div>
								</div>
							</div>
							<div class="ptd mark" style="margin-left: 180px;">
								<div class="btn">
									{{payed_item.mark?payed_item.mark:'暂无备注'}}
								</div>
								<div style="clear:both"></div>
								<div class="btn">分组编号-司机:{{payed_item.group_num_driver}}</div>
								<div style="clear:both"></div>
								<div class="btn">分组编号-收款人:{{payed_item.group_num_truck_owner}}</div>
								<div style="clear:both"></div>
								<div class="btn">光大打款状态:{{payed_item.out_cash_type_text}}</div>
								<div style="clear:both"></div>
								<div class="btn">{{payed_item.out_cash_num_text}}</div>
							    <div v-if="payed_item.out_cash_type==4">
									<div class="btn" v-if="payed_item.zz_to_jg_status==4">转账到骏功失败{{payed_item.zz_to_jg_num}}</div>
									<div class="btn" v-if="payed_item.fenzhang_status==4">转账到司机失败{{payed_item.fenzhang_num}}</div>
									<div class="btn" v-if="payed_item.huizong_status==4">资金汇总失败{{payed_item.huizong_num}}</div>
									<div class="btn" v-if="payed_item.out_cash_status==5">出款失败{{payed_item.out_cash_num}}</div>
								</div>
								<div class="btn blue" v-if="user_info.ug_info.name=='开发者'" @click="payed_item_show(payed_item)">修改</div>
								<!-- <div style="clear:both"></div>
								<div class="btn">服务费(光大):{{payed_item.service_pay_num}}/{{payed_item.service_pay_status}}</div>
								<div style="clear:both"></div>
								<div class="btn">货主转骏功(光大):{{payed_item.fenzhang_num}}/{{payed_item.fenzhang_status}}</div>
								<div style="clear:both"></div>
								<div class="btn">汇总编号(光大):{{payed_item.huizong_num}}/{{payed_item.huizong_status}}</div>
								<div style="clear:both"></div>
								<div class="btn">出款编号(光大):{{payed_item.out_cash_num}}/{{payed_item.out_cash_status}}</div> -->
								<!-- <el-popover
									placement="bottom"
									width="200"
									trigger="hover"
									:content="">
									<el-button type="text" slot="reference">查看备注</el-button>
								</el-popover> -->
								<!-- <div class="btn"  v-if="!payed_item.mark">查看备注:{{payed_item.mark}}</div> -->
							</div>
							<div class="ptd" style="margin-left:auto;width:60px">
								<div class="btn blue" @click="to_outcash_list(payed_item.payed_num)">出金记录</div>
								<el-button @click="show_voucher(payed_item.payed_num,payed_item.this_pay_type)"
									size="mini" type="text">打款凭证</el-button>
								<div class="btn blue" @click="accelerate_payment(payed_item.id)">加速打款</div>
							</div>
						</div>
					</div>
				</div>
				<div id="bottom"></div>
			</div>
		</div>

		<!-- 分页 -->
		<el-pagination class="margin-top-xl" @size-change="page_size_change" @current-change="page_current_change"
			:current-page="page.p" :page-sizes="[10, 50, 100, 200]" :page-size="page.num" :total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"></el-pagination>
		<el-backtop target=".tbody" :bottom="100" :visibility-height="50"></el-backtop>
		<!-- 打款凭证弹窗 -->
		<el-dialog top="2vh" width="800px" title="打款凭证" :visible.sync="voucher.is_show">
			<el-image class="img" :src="voucher.src" :previewSrcList="voucher.srclist" :z-index=3000>
			</el-image>
			<!-- <img :src="voucher.src" style="width:100%"> -->
		</el-dialog>
		<!-- 电子回单查看 -->
		<el-dialog top="1vh" width="80%" :visible.sync="receipt.is_show">
			<div class="bottom_btns">
				<el-button type="primary" @click="receipt_download(receipt.data)">下载</el-button>
			</div>
			<pdf :src="receipt.src"></pdf>

		</el-dialog>
		<!-- 批量打款弹窗 -->
		<el-dialog top="50vh" width="20%" :visible.sync="batch_pay.is_show">
			当前打款第{{batch_pay.n}}条
		</el-dialog>
		<!-- 搜索表单 -->
		<serForm :is_show="ser_form_is_show" :form="form" @sub="ser_form_sub"></serForm>

		<!-- 现金结算界面 -->
		<cashSettle @settle_end="settle_close" :tord_info="cash_settle.tord_info" :payed_info="cash_settle.payed_info"
			:settle_target="cash_settle.settle_target" :is_show="cash_settle.is_show" @loading_close="loading_close">
		</cashSettle>

		<!-- 燃油费结算界面 -->
		<fuelCostSettle @settle_end="settle_close" :tord_info="fuel_cost_settle.tord_info"
			:payed_info="fuel_cost_settle.payed_info" :settle_target="cash_settle.settle_target"
			:is_show="fuel_cost_settle.is_show" @loading_close="loading_close"></fuelCostSettle>

		<!-- 过路费结算界面 -->
		<roadTollSettle @settle_end="settle_close" :tord_info="road_toll_settle.tord_info"
			:payed_info="road_toll_settle.payed_info" :settle_target="cash_settle.settle_target"
			:is_show="road_toll_settle.is_show" @loading_close="loading_close"></roadTollSettle>

		<!-- 导出 -->
		<serRelExport :is_show="tord_export.is_show" :rull="tord_export.rull" @close="tord_export_close"></serRelExport>

		<!-- 批量出金界面 -->
		<cashSettleCreat :data="settle_creat.data" :is_show="settle_creat.is_show" @cash_settle_end="cash_settle_close">
		</cashSettleCreat>

		
		<!-- 光大出款失败修改 -->
		<el-dialog
				title="光大出款失败修改"
				:visible.sync="remittance_edit_show"
				width="30%"
				top="30vh"
				@close="remittance_edit_close"
			>
			<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="remittance_edit.zz_to_jg_num" placeholder="转账给骏功" auto-complete="new-password">
					<el-button style="width:100px" slot="prepend">转账给骏功</el-button>
					<el-button slot="append">{{remittance_edit.zz_to_jg_status}}</el-button>
				</el-input>
				<div>
					
				</div>
			</el-form-item>
			<el-form-item>
				<el-input v-model="remittance_edit.fenzhang_num" placeholder="转账给司机" auto-complete="new-password">
					<el-button style="width:100px" slot="prepend">转账给司机</el-button>
					<el-button slot="append">{{remittance_edit.fenzhang_status}}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="remittance_edit.huizong_num" placeholder=" 汇总" auto-complete="new-password" >
					<el-button style="width:100px" slot="prepend"> 汇总</el-button>
					<el-button slot="append">{{remittance_edit.huizong_status}}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="remittance_edit.out_cash_num" placeholder=" 出款" auto-complete="new-password" >
					<el-button style="width:100px" slot="prepend"> 出款</el-button>
					<el-button slot="append">{{remittance_edit.out_cash_status}}</el-button>
				</el-input>
			</el-form-item>
			<div>
			</div>
		</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="remittance_edit_show = false">取 消</el-button>
				<el-button type="primary" @click="remittance_edit_sub">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 批量回款 -->
			<!-- 光大出款失败修改 -->
		<el-dialog
				title="批量回款"
				:visible.sync="tord_pay_return_admin.is_show"
				width="30%"
				top="30vh"
				@close="remittance_edit_close"
			>
			<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="tord_pay_return_admin.bank_ra_num" placeholder="银行流水号" auto-complete="new-password">
					<el-button style="width:150px" slot="prepend">银行流水号</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="tord_pay_return_admin.much" placeholder="本次回款总金额" auto-complete="new-password">
					<el-button style="width:150px" slot="prepend">本次回款总金额</el-button>
				</el-input>
			</el-form-item>

			<div>
			</div>
		</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="tord_pay_return_admin.is_show = false">取 消</el-button>
				<el-button type="primary" @click="tord_pay_return_admin_sub">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import serForm from './ser_form.vue'
	import cashSettle from '../tord_settle/cash_settle.vue'
	import fuelCostSettle from '../tord_settle/fuel_cost_settle.vue'
	import roadTollSettle from '../tord_settle/road_toll_settle.vue'
	import serRelExport from './pay_excle.vue'
	import cashSettleCreat from './cash_settle_creat.vue'
	import {
		Loading
	} from "element-ui";
	import pdf from 'vue-pdf'
	export default {
		components: {
			serForm,
			cashSettle,
			fuelCostSettle,
			roadTollSettle,
			serRelExport,
			cashSettleCreat,
			pdf,
		},
		data() {
			return {
				remittance_edit_show:false,
				receipt: {
					is_show: false,
					src: '',
					bstr: '',
					data: '',
				},
				total: {
					total_truck_owner: 0,
					total_driver: 0
				},

				//搜索界面是否打开
				ser_form_is_show: 0,

				//搜索条件
				form: {
					payed_num: '', //支付编号
					truck_tord_num: '', //货源编号
					shipper_tel: '', //货主手机号
					truck_owner_tel: '', //收款人手机号
					truck_owner_name: '', //收款人姓名
					driver_tel: '', //司机手机号
					driver_name: '', //司机姓名
					truck_plate_num: '', //车牌号
					this_pay_type: '', //本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status: '', //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status: '', //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status: '2', //结算给收款人的状态(1:未申请,2:待打款,3:已打款,4:结算失败)	
					mark: '', //备注
					payed_time_start: '', //开始时间
					payed_time_end: '', //结束时间
					settled_time_start: '',
					settled_time_end: '',
					bind_bankcard_status: '', //绑卡状态
					id: '', //id搜索
					group_num_driver: '', //司机出款流水号
					group_num_truck_owner: '', //收款人出款流水号 
					out_cash_failed:'',//光大出款状态
					is_yufu:'',//是否垫资

					is_admin_pay_return:'',//是否回款
				},
				settled_value: '', //结算时间
				//表格数据
				list: {
					loading: false, //loading特效
					data: [], //列表数据
					total: 0, //列表总记录数
				},

				//页码信息
				page: {
					need_num: 1,
					num: 10,
					p: 1,
				},
				//时间快捷搜索
				date_value: '',
				pickerOptions: {
					shortcuts: [{
							text: "最近一周",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近一个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "最近三个月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
				//凭证弹出层
				voucher: {

					//是否显示
					is_show: false,

					//地址
					src: '',

					srclist: []
				},
				//批量打款弹窗
				batch_pay: {
					is_show: false,
					n: '',
				},
				//现金结算页面
				cash_settle: {
					is_show: 0, //是否显示
					settle_target: '', //结算目标
					tord_info: {}, //运单数据
					payed_info: {}, //支付记录
				},

				//燃油费结算页面
				fuel_cost_settle: {
					is_show: 0, //是否显示
					settle_target: '', //结算目标
					tord_info: {}, //运单数据
					payed_info: {}, //支付记录
				},

				//过路费结算页面
				road_toll_settle: {
					is_show: 0, //是否显示
					settle_target: '', //结算目标
					tord_info: {}, //运单数据
					payed_info: {}, //支付记录
				},

				//导出搜索结果界面
				tord_export: {
					is_show: 0,
					rull: {}
				},

				//多选
				payed_num_choosed: {},

				//id作键名的列表数组
				list_data_idx: [],

				//出金界面
				settle_creat: {
					is_show: 0, //是否显示
					data: {}, //支付数据
				},
				download_receipt: [],
				num: 0,
				//光大汇款修改
				remittance_edit:{
					id:'',
					zz_to_jg_num:'', //转账给骏功
					fenzhang_num:'', //转账给司机
					huizong_num:'', //汇总
					out_cash_num:'', //出款
					zz_to_jg_status:'', //转账给骏功状态
					fenzhang_status:'', //分账给司机状态
					huizong_status:'',  //汇总状态
					out_cash_status:'',  //出款状态
				},
				is_outcash:'',//自动出款是否已开启 1已开启 2未开启
				//回款弹窗
				tord_pay_return_admin:{
					is_show:false,
					bank_ra_num:'',
					much:'',
				}
			}
		},
		computed: {
			...mapState(['user_info'])
		},
		created() {
			//取出参数
			let query = this.$route.query;
			for (var i in query) {
				this.form[i] = query[i]
			}

			//读取货源
			this.get_page_data()

			//查询自动出款是否开启
			this.get_auto_outcash()
		},

		methods: {
			//批量撤回回款
			tord_pay_return_admin_cancel(){
				var choosed = this.get_payed_choosed()
				if (choosed.length == 0) {
					this.$my.other.msg({
						type: "info",
						str: '当前未勾选数据'
					});
					return
				}
				let payed_nums = []
				choosed.forEach(item=>{
					payed_nums.push(item.payed_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_return_admin_cancel',
						payed_nums
					},callback:((data)=>{
						//刷新界面
						this.get_page_data()
						this.tord_pay_return_admin.is_show = false
					})
				})
			},
			//批量回款支付
			tord_pay_return_admin_sub(){
				var choosed = this.get_payed_choosed()
				let payed_nums = []
				choosed.forEach(item=>{
					payed_nums.push(item.payed_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_return_admin',
						bank_ra_num:this.tord_pay_return_admin.bank_ra_num,
						much:this.tord_pay_return_admin.much,
						payed_nums
					},callback:((data)=>{
						//刷新界面
						this.get_page_data()
						this.tord_pay_return_admin.is_show = false
					})
				})
			},
			//批量回款支付弹窗打开
			tord_pay_return_admin_open(){
				this.tord_pay_return_admin.is_show = true
				var choosed = this.get_payed_choosed()
				if (choosed.length == 0) {
					this.$my.other.msg({
						type: "info",
						str: '当前未勾选数据'
					});
					return
				}
			},
			//开启关闭自动出款
			get_auto_outcash_set(){
				let is_outcash = ''
				if(this.is_outcash==1){
					is_outcash =2
				}else{
					is_outcash =1
				}
			   this.$my.net.req({
					data:{
						mod:'timer',
						ctr:'change_auto_outcash',
						is_outcash
					},callback:(data)=>{
						this.is_outcash = data.is_outcash
						if(is_outcash==2){
							this.$my.other.msg({
								str:'自动打款关闭成功',
								type:'success'
							});
						}else{
							this.$my.other.msg({
								str:'自动打款开启成功',
								type:'success'
							});
						}
					}
				})
			},
			//自动出款是否开启
			get_auto_outcash(){
				this.$my.net.req({
					data:{
						mod:'timer',
						ctr:'get_auto_outcash',
					},callback:(data)=>{
						this.is_outcash = data.is_outcash
					}
				})
			},
			//光大修改弹窗关闭
			remittance_edit_close(){
				this.remittance_edit={
					id:'',
					zz_to_jg_num:'', //转账给骏功
					fenzhang_num:'', //转账给司机
					huizong_num:'', //汇总
					out_cash_num:'', //出款
					zz_to_jg_status:'', //转账给骏功状态
					fenzhang_status:'', //分账给司机状态
					huizong_status:'',  //汇总状态
					out_cash_status:'',  //出款状态
				}
			},
			//光大修改提交
			remittance_edit_sub(){
				this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'tord_payed_list_edit',
						id:this.remittance_edit.id,
						zz_to_jg_num:this.remittance_edit.zz_to_jg_num,
						fenzhang_num:this.remittance_edit.fenzhang_num,
						huizong_num:this.remittance_edit.huizong_num,
						out_cash_num:this.remittance_edit.out_cash_num,
                    },callback:(data)=>{
						//提醒
						this.$my.other.msg({
							str:'编辑成功',
							type:'success'
						});
						this.remittance_edit_show = false
                    }
                })
			},
			//打开光大出款弹窗
			payed_item_show(item){
				console.log(item)
				this.remittance_edit.zz_to_jg_num = item.zz_to_jg_num
				this.remittance_edit.fenzhang_num = item.fenzhang_num
				this.remittance_edit.huizong_num = item.huizong_num
				this.remittance_edit.out_cash_num = item.out_cash_num

				this.remittance_edit.zz_to_jg_status = item.zz_to_jg_status
				this.remittance_edit.fenzhang_status = item.fenzhang_status
				this.remittance_edit.huizong_status = item.huizong_status
				this.remittance_edit.out_cash_status = item.out_cash_status
				this.remittance_edit.id = item.id
				this.remittance_edit_show = true
			},
			//批量加速打款
			hs_accelerate_payment(num){
				Loading.service(this.options);
				var choosed = this.get_payed_choosed()
				if (choosed.length == 0) {
					this.$my.other.msg({
						type: "info",
						str: '当前未勾选数据'
					});
					this.close_loading()
					return
				}
				//关闭等待特效
				if (num == choosed.length) {
					this.close_loading()
					return
				}
				this.$my.net.req({
					take_over_control: 1,
                    data:{
                        mod:'timer',
                        ctr:'auto_cash_cebbank',
                        key:'TTabaYl77Ulh6gTR6lw9TYExxitACnYa',
						id:choosed[num].id
                    },callback:(data)=>{
						if(data.code ==0){
							//提醒
							this.$my.other.msg({
								str:'操作成功',
								type:'success'
							});
						}else{
							//提醒
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
						}
						//下一条
						num++
						this.hs_accelerate_payment(num)
                    }
                })
			},
			//加速打款
			accelerate_payment(id){
				this.$my.net.req({
                    data:{
                        mod:'timer',
                        ctr:'auto_cash_cebbank',
                        key:'TTabaYl77Ulh6gTR6lw9TYExxitACnYa',
						id
                    },callback:(data)=>{
						//提醒
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
                    }
                })
			},
			truck_owner_download_receipt(n) {
				var choosed = this.get_payed_choosed()
				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'hsbank_receipt',
						payed_num: choosed[n].payed_num,
						settle_target: 'truck_owner'
					},
					callback: (data) => {
						let src = 'data:application/pdf;base64,' + data.msg
						var name = ''
						var card_num = ''
						if (choosed[n].truck_owner_bank_list.num >= 1) {
							name = choosed[n].truck_owner_bank_list.list[0].card_user_name
							card_num = choosed[n].truck_owner_bank_list.list[0].card_num
						}
						const filename = name + '-' + card_num + '-' + choosed[n].this_settled_text + '-' +
							choosed[n].truck_tord_num + ".pdf"
						const a = document.createElement('a')
						a.href = src
						a.setAttribute('download', filename)
						a.click()
						this.num++
						this.hsbank_download_receipt()
					}
				})
			},
			//批量下载回单
			hsbank_download_receipt() {
				Loading.service(this.options);
				var choosed = this.get_payed_choosed()
				if (choosed.length == 0) {
					this.$my.other.msg({
						type: "info",
						str: '当前未勾选数据'
					});
					this.close_loading()
					return
				}
				//关闭等待特效
				if (this.num == choosed.length) {
					this.close_loading()
					this.num = 0
					return
				}
				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'hsbank_receipt',
						payed_num: choosed[this.num].payed_num,
						settle_target: 'driver'
					},
					callback: (data) => {
						if (data.code == 0) {
							let src = 'data:application/pdf;base64,' + data.msg
							this.receipt.bstr = src
							this.receipt_download(choosed[this.num])
						}
						//查询是否有车队长收款
						this.truck_owner_download_receipt(this.num)
					}
				})

			},
			receipt_download(item) {

				var name = ''
				var card_num = ''

				if (item.truck_owner_bank_list.num >= 1) {
					name = item.truck_owner_bank_list.list[0].card_user_name
					card_num = item.truck_owner_bank_list.list[0].card_num
				}
				if (item.driver_bank_list.num >= 1) {
					name = item.driver_bank_list.list[0].card_user_name
					card_num = item.driver_bank_list.list[0].card_num
				}
				const filename = name + '-' + card_num + '-' + item.this_settled_text + '-' + item.truck_tord_num + ".pdf"
				// var element = document.createElement('a')
				// element.setAttribute('href', encodeURI(this.receipt.bstr))
				// element.setAttribute('download', 'LoginInquiry.pdf')
				// element.setAttribute('download', filename)
				// element.style.display = 'none'
				// document.body.appendChild(element)
				// element.click()
				// document.body.removeChild(element)
				const a = document.createElement('a')
				a.href = this.receipt.bstr
				a.setAttribute('download', filename)
				a.click()
			},
			close_loading() {
				let loadingInstance = Loading.service(this.options);
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
			},

			//查看回单
			see_receipt(item, type) {
				this.receipt.data = item
				Loading.service(this.options);
				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'hsbank_receipt',
						payed_num: item.payed_num,
						settle_target: type
					},
					callback: (data) => {
						if (data.code == 0) {
							this.receipt.is_show = true
							let src = 'data:application/pdf;base64,' + data.msg
							this.receipt.bstr = src
							this.receipt.src = pdf.createLoadingTask(src)
							this.close_loading()
						} else {
							this.$my.other.msg({
								type: "info",
								str: data.error_info
							});
							this.close_loading()
						}
					}
				})
			},
			cash_settle_close() { //关闭打款界面

				//打款界面关闭后
				this.settle_creat.is_show = 0;

				//清空打款界面数据
				this.settle_creat.data = {};

				//刷新数据
				this.get_page_data()
			},
			//批量人工打款
			hsbank_settle_open() {
				var choosed = this.get_payed_choosed()
				if (choosed.length == 0) {
					this.$my.other.msg({
						type: "info",
						str: '当前未勾选数据'
					});
					return
				}
				let payed_nums = []
				let bankcard = ''
				let this_settled = 0
				//遍历取出支付编号
				choosed.forEach((item, index) => {
					payed_nums.push(item.payed_num)
					this_settled += item.this_settled
					if (!bankcard) {
						if (item.truck_owner_bank_list.list.length != 0) {
							bankcard = item.truck_owner_bank_list.list[0]
							return
						} else if (item.driver_bank_list.list.length != 0) {
							bankcard = item.driver_bank_list.list[0]
							return
						}
					}
				})
				//置入数据
				this.settle_creat.data = {
					payed_num: payed_nums,
					bankcard: bankcard,
					this_settled: this_settled,
				};
				this.settle_creat.is_show++
			},
			//徽商打款查询
			cash_settle_ser_by_hsbank(item, type) {
				this.$my.net.req({
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'ser_pay_result_by_hsbank',
						payed_num: item.payed_num,
						settle_target: type,
					},
					callback: (data) => {

						this.$my.other.msg({
							type: "success",
							str: '打款成功'
						});

					}
				});
			},
			hsbank_pay_batch_driver(n, fun) {
				this.batch_pay.is_show = true
				n = n ? n : 0;
				let that = this;
				var data = this.get_payed_choosed()
				let one = data[n];
				if (!one) {
					//司机循环打款完毕  --  触发车队长打款
					if (fun) {
						this.batch_pay.n = 0;
						fun(0);
					} else {
						//弹窗提醒  已经批量打款完毕
						this.batch_pay.is_show = false
						this.n = 0
						this.$my.other.msg({
							type: 'success',
							str: '打款完毕'
						});
					}
					return;
				}
				this.batch_pay.n = n + 1

				let info = this.list_data_idx[one.payed_num];

				let skip = false;

				//已打款的不做处理
				if (info.settle_status_of_driver != 2) {
					skip = true;
				}

				//司机打款处理
				if (info.settle_status_of_driver == 2 && info.this_settled_of_driver > 0) { //司机待打款

					//收款银行卡多个的不做处理
					if (info.driver_bank_list.num != 1) {
						console.log(info.payed_num + "银行信息不对");
						skip = true;
					}

					//打款中的不做处理
					if (info.api_text) {
						let api_text = JSON.parse(info.api_text);
						console.log(info.payed_num + "已发起打款");
						if (api_text && api_text.driver_send && api_text.driver_send.data_list && api_text.driver_send
							.data_list.fun) { //已发起打款，不做处理
							skip = true;
						}
					}

				}

				//0元跳过.
				if (info.this_settled_of_driver == 0) {
					skip = true;
				}

				if (skip) {
					setTimeout(function() {
						console.log("司机跳过 下一个");
						n++;
						that.hsbank_pay_batch_driver(n, fun);
					}, 100);
					return;
				}


				//发起徽商银行打款
				let bank_info = info.driver_bank_list.list[0];
				// setTimeout(function(){
				// 	console.log("司机下一个");
				// 	n++;
				// 	that.hsbank_pay_batch_driver(n,fun);
				// },100);

				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'payed_by_hsbank',
						payed_num: info.payed_num,
						bankcard: bank_info,
						settle_target: "driver",
					},
					callback: (data) => {
						if (data.code != 0) {
							this.$my.other.msg({
								type: 'info',
								str: data.error_info
							});
						}
						//下一个

						setTimeout(function() {
							n++;
							that.hsbank_pay_batch_driver(n, fun);
						}, 100);

					}
				});
			},
			hsbank_pay_batch_truck_owner(n) {
				this.batch_pay.is_show = true
				n = n ? n : 0;
				let that = this;
				var data = this.get_payed_choosed()
				let one = data[n];
				if (!one) {
					//司机 车队 打款都已完毕
					//弹窗提醒  已经批量打款完毕
					this.batch_pay.is_show = false
					this.n = 0
					this.$my.other.msg({
						type: 'success',
						str: '打款完毕'
					});
					return;
				}
				this.batch_pay.n = n + 1

				let info = this.list_data_idx[one.payed_num];

				let skip = false;

				//已打款的不做处理
				if (info.settle_status_of_truck_owner != 2) {
					console.log(one.payed_num + "已打款的不做处理");
					skip = true;
				}

				//车队打款处理
				if (info.settle_status_of_truck_owner == 2 && info.this_settled_of_truck_owner > 0) { //车队待打款

					//收款银行卡多个的不做处理
					if (info.truck_owner_bank_list.num != 1) {
						console.log(one.payed_num + "多个银行卡");
						skip = true;
					}

					//打款中的不做处理
					if (info.api_text) {
						let api_text = JSON.parse(info.api_text);
						console.log(info.payed_num + "已发起打款");
						if (api_text && api_text.truck_owner_send && api_text.truck_owner_send.data_list && api_text
							.truck_owner_send.data_list.fun) { //已发起打款，不做处理
							skip = true;
						}
					}

				}

				//0元跳过.
				if (info.this_settled_of_truck_owner == 0) {
					skip = true;
				}

				if (skip) {
					setTimeout(function() {
						console.log("车队跳过 下一个");
						n++;
						that.hsbank_pay_batch_truck_owner(n);
					}, 100);
					return;
				}

				//发起徽商银行打款
				let bank_info = info.truck_owner_bank_list.list[0];

				// setTimeout(function(){
				// 	console.log("车队下一个");
				// 	n++;
				// 	that.hsbank_pay_batch_truck_owner(n);
				// },100);

				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'payed_by_hsbank',
						payed_num: info.payed_num,
						bankcard: bank_info,
						settle_target: "truck_owner",
					},
					callback: (data) => {
						if (data.code != 0) {
							this.$my.other.msg({
								type: 'info',
								str: data.error_info
							});
						}
						//下一个
						setTimeout(function() {
							n++;
							that.hsbank_pay_batch_truck_owner(n);
						}, 100);
					}
				});
			},
			//徽商批量查询
			hsbank_ser_batch(n) {
				let that = this;
				this.hsbank_ser_batch_driver(n, function() { //先循环一遍给司机查询
					console.log("开始查询车队打款情况")
					that.hsbank_ser_batch_truck_owner(n); //再循环一遍 给车队长查询
				});
			},
			hsbank_ser_batch_driver(n, fun) {
				this.batch_pay.is_show = true
				n = n ? n : 0;
				let that = this;
				var data = this.get_payed_choosed()
				let one = data[n];
				if (!one) {
					//司机循环打款完毕  --  触发车队长打款
					if (fun) {
						console.log("司机查询完毕，开始车队查询")
						this.batch_pay.n = 0;
						fun(0);
					} else {
						//弹窗提醒  已经批量打款完毕
						this.batch_pay.is_show = false
						this.n = 0
						this.$my.other.msg({
							type: 'success',
							str: '查询完毕'
						});
					}
					return;
				}
				this.batch_pay.n = n + 1

				let info = this.list_data_idx[one.payed_num];

				let skip = false;

				//已打款的不做处理
				if (info.settle_status_of_driver != 2) {
					skip = true;
				}

				//司机打款处理
				if (info.settle_status_of_driver == 2 && info.this_settled_of_driver > 0) { //司机待打款 

					//未发起打款的不做处理
					if (!info.api_text) {
						skip = true;
					}

				}

				//0元跳过.
				if (info.this_settled_of_driver == 0) {
					skip = true;
				}

				if (skip) {
					setTimeout(function() {
						console.log("司机查询跳过 下一个");
						n++;
						that.hsbank_ser_batch_driver(n, fun);
					}, 100);
					return;
				}
				//司机打款查询
				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'ser_pay_result_by_hsbank',
						payed_num: info.payed_num,
						settle_target: "driver",
					},
					callback: (data) => {

						//下一个
						setTimeout(function() {
							n++;
							that.hsbank_ser_batch_driver(n, fun);
						}, 100);
					}
				});
			},
			hsbank_ser_batch_truck_owner(n) {
				this.batch_pay.is_show = true
				n = n ? n : 0;
				let that = this;
				var data = this.get_payed_choosed()
				let one = data[n];
				if (!one) {
					//司机 车队 打款都已完毕
					//弹窗提醒  已经批量打款完毕
					this.batch_pay.is_show = false
					this.n = 0
					this.$my.other.msg({
						type: 'success',
						str: '查询完毕'
					});
					return;
				}
				this.batch_pay.n = n + 1

				let info = this.list_data_idx[one.payed_num];

				let skip = false;

				//已打款的不做处理
				if (info.settle_status_of_truck_owner != 2) {
					console.log(one.payed_num + "已打款的不做处理");
					skip = true;
				}

				//车队打款处理
				if (info.settle_status_of_truck_owner == 2 && info.this_settled_of_truck_owner > 0) { //车队待打款

					//未打款的不做处理
					if (!info.api_text) {
						skip = true;
					}

				}

				//0元跳过.
				if (info.this_settled_of_truck_owner == 0) {
					skip = true;
				}

				if (skip) {
					setTimeout(function() {
						console.log("车队跳过 下一个");
						n++;
						that.hsbank_ser_batch_truck_owner(n);
					}, 100);
					return;
				}

				//发起徽商银行查询

				// setTimeout(function(){
				// 	console.log("车队下一个");
				// 	n++;
				// 	that.hsbank_pay_batch_truck_owner(n);
				// },100);

				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'ser_pay_result_by_hsbank',
						payed_num: info.payed_num,
						settle_target: "truck_owner",
					},
					callback: (data) => {
						//下一个
						setTimeout(function() {
							n++;
							that.hsbank_ser_batch_truck_owner(n);
						}, 100);
					}
				});
			},
			//徽商银行批量打款
			hsbank_pay_batch(n) {
				let that = this;
				this.hsbank_pay_batch_driver(n, function() { //先循环一遍给司机打款
					that.hsbank_pay_batch_truck_owner(n); //再循环一遍 给车队长打款
				});



				// 			this.batch_pay.is_show = true
				// 			n=n?n:0;
				// 			let that=this;
				// 			var data = this.get_payed_choosed()
				// 			let one=data[n];
				// 			if(!one){
				// 				//弹窗提醒  已经批量打款完毕
				// 				this.batch_pay.is_show = false
				// 				this.n = 0
				// 				this.$my.other.msg({
				// 					type:'success',
				// 					str:'打款完毕'
				// 				})
				// 				return;
				// 			}
				// 			this.batch_pay.n = n +1
				// 			// console.log(one);
				// 			let info=this.list_data_idx[one.payed_num];
				// 			console.log(info);
				// 			return;

				// 			let skip=false;

				// 			//已打款的不做处理
				// 			if(info.settle_status_of_driver!=2&&info.settle_status_of_truck_owner!=2){
				// 				skip=true;
				// 			}

				// 			//司机打款处理
				// 			if(info.settle_status_of_driver==2&&info.this_settled_of_driver>0){//司机待打款

				// 				//收款银行卡多个的不做处理
				// 				if(info.driver_bank_list.num!=1){
				// 					skip=true;
				// 				}

				// 				//打款中的不做处理
				// 				if(info.api_status==1){
				// 					skip=true;
				// 				}

				// 				//发起打款

				// 			}

				// 			if(skip){
				// 				setTimeout(function(){
				// 					n++;
				// 					that.hsbank_pay_batch(n);
				// 				},100);
				// 				return;
				// 			}



				// 			setTimeout(function(){
				// 				n++;
				// 				that.hsbank_pay_batch(n);
				// 			},100);
			},
			//打款凭证
			show_voucher(payed_num, settle_target) {
				this.$my.net.req({
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'ser_voucher_by_hsbank',
						payed_num,
						settle_target
					},
					callback: (data) => {
						//console.log(data,111);
					}
				})
			},
			loading_close() {
				// 加载完毕
				let loadingInstance = Loading.service(this.options);
				this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//多选
			payed_all_choose(event) { //全选
				if (event) {
					for (var payed_num in this.payed_num_choosed) {
						this.payed_num_choosed[payed_num].checked = true;
					}
				} else {
					for (var payed_num in this.payed_num_choosed) {
						this.payed_num_choosed[payed_num].checked = false;
					}
				}
			},
			get_payed_choosed() { //获取选中的值
				let choosed = [];
				for (var payed_num in this.payed_num_choosed) {
					let item = {
						...this.payed_num_choosed[payed_num]
					};
					if (item.checked) {
						choosed.push(item);
					}
				}

				return choosed;
			},

			//前往查看出金记录
			to_outcash_list(payed_num) {

				//跳转
				this.$router.push({
					path: '/pages/finance/tord_real_time_outcash_list',
					query: {
						payed_num: payed_num
					}
				});
			},
			//新司机重启结算
			settle_open1(item) {
				this.$my.other.confirm({
					content: "是否重启该笔结算?",
					confirm: () => {

						//调接口
						this.$my.net.req({
							data: {
								mod: 'truck_tord_real_time',
								ctr: 'settle_cancel_driver',
								payed_num: item.payed_num,
							},
							callback: (data) => {
								this.$my.other.msg({
									type: 'success',
									str: '操作成功'
								});
								//刷新页面
								this.get_page_data()
							},
						});
					}
				});
			},
			//打开结算界面
			settle_open(tord_index, payed_index, settle_target) {

				Loading.service(this.options);

				let tord_info = JSON.parse(JSON.stringify(this.list.data[tord_index]))

				let payed_info = JSON.parse(JSON.stringify(tord_info.payed_list[payed_index]))

				delete(tord_info.payed_list)

				switch (payed_info.this_pay_type) {

					case '1': //现金
						this.cash_settle.tord_info = tord_info;
						this.cash_settle.payed_info = payed_info;
						this.cash_settle.settle_target = settle_target;
						this.cash_settle.is_show++;
						break;

					case '2': //燃油费
						this.fuel_cost_settle.tord_info = tord_info;
						this.fuel_cost_settle.payed_info = payed_info;
						this.cash_settle.settle_target = settle_target;
						this.fuel_cost_settle.is_show++;
						break;

					case '3': //过路费
						this.road_toll_settle.tord_info = tord_info;
						this.road_toll_settle.payed_info = payed_info;
						this.cash_settle.settle_target = settle_target;
						this.road_toll_settle.is_show++;
						break;
				}
			},
			settle_close(this_pay_type) { //关闭结算界面

				switch (this_pay_type) {

					case '1': //现金
						this.cash_settle.is_show = 0; //关闭界面
						this.cash_settle.tord_payed_info = {}; //清空数据
						break;

					case '2': //燃油费
						this.fuel_cost_settle.is_show = 0; //关闭界面
						this.fuel_cost_settle.tord_payed_info = {}; //清空数据
						break;

					case '3': //过路费
						this.road_toll_settle.is_show = 0; //关闭界面
						this.road_toll_settle.tord_payed_info = {}; //清空数据
						break;
				}

				//刷新
				this.get_page_data()
			},


			//驳回结算
			// tords_settle_refuse(){

			// 	//获取选中值
			// 	let payed_choosed=this.get_payed_choosed();

			// 	//没有选择支付记录
			// 	if(payed_choosed.length==0){
			// 		this.$my.other.msg({
			// 			type:'warning',
			// 			str:'请选择支付记录'
			// 		})
			// 		return;
			// 	}

			// 	//汇集需要到货的支付记录
			// 	let payed_list=[];
			// 	for(var item of payed_choosed){
			// 		if(item.settle_status==2){
			// 			payed_list.push({
			// 				truck_tord_num:item.truck_tord_num,
			// 				payed_num:item.payed_num,
			// 			});
			// 		}
			// 	}

			// 	//已选择支付记录中没有可以驳回的
			// 	if(payed_list.length==0){
			// 		this.$my.other.msg({
			// 			type:'warning',
			// 			str:'已选择支付记录中没有可以驳回的'
			// 		})
			// 		return;
			// 	}

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击确定驳回结算申请",
			// 		confirm:()=>{

			// 			//调用接口
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_settle_refuse_by_admin',
			// 					payed_list:payed_list,
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				}
			// 			})
			// 		}
			// 	})
			// },
			// settle_refuse(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击确定驳回打款申请",
			// 		confirm:()=>{

			// 			//调用接口
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_settle_refuse_by_admin',
			// 					payed_list:[
			// 						{
			// 							payed_num:item.payed_num,
			// 						}
			// 					],
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				}
			// 			})
			// 		}
			// 	})
			// },

			//撤回结算
			tords_settle_revoke(settle_target, n) {
				//获取选中值
				let payed_choosed = this.get_payed_choosed();
				//没有选择支付记录
				if (payed_choosed.length == 0) {
					this.$my.other.msg({
						type: 'warning',
						str: '请选择支付记录'
					})
					return;
				}

				//汇集需要到货的支付记录
				let payed_list = [];
				// 8.9号 吕昕要求 打款失败也可进行撤销出款
				for (var item of payed_choosed) {
					console.log(item)
					if (settle_target == 'truck_owner' && item.settle_status_of_truck_owner == 3 || item.settle_status_of_truck_owner == 4) {
						payed_list.push({
							truck_tord_num: item.truck_tord_num,
							payed_num: item.payed_num,
						});
					} else if (settle_target == 'driver' && item.settle_status_of_driver == 3|| item.settle_status_of_driver == 4) {
						payed_list.push({
							truck_tord_num: item.truck_tord_num,
							payed_num: item.payed_num,
						});
					}
				}

				//已选择支付记录中没有可以撤回的
				if (payed_list.length == 0) {
					this.$my.other.msg({
						type: 'warning',
						str: '已选择支付记录中没有可以撤回的'
					})
					return;
				}
				if (n == 0) {
					//询问
					this.$my.other.confirm({
						content: "点击确定撤销打款申请",
						confirm: () => {
							this.tords_settle_revoke_for(payed_list[n], settle_target, n)
						}
					})
				} else {
					if (!payed_list[n]) {
						// //提醒
						this.$my.other.msg({
							str: '操作成功',
							type: 'success'
						});

						//刷新本页
						this.get_page_data()
						return
					}
					this.tords_settle_revoke_for(payed_list[n], settle_target, n)
				}

			},
			tords_settle_revoke_for(item, settle_target, n) {
				n++
				//调用接口
				this.$my.net.req({
					take_over_control: 1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'settle_cancel',
						pay_type: settle_target == 'truck_owner' ? 1 : 2,
						// payed_list:payed_list
						payed_num: item.payed_num
					},
					callback: (data) => {
						if (data.code == 0) {
							this.$my.other.msg({
								str: '当前第' + n + "条，" + item.payed_num + '操作成功',
								type: 'success'
							});
						} else {
							this.$my.other.msg({
								str: data.error_info,
								type: 'info'
							});
						}
						this.tords_settle_revoke(settle_target, n)
					}
				})
			},
			settle_revoke(item, settle_target) {

				//询问
				this.$my.other.confirm({
					content: "点击确定撤销打款申请",
					confirm: () => {

						//调用接口
						this.$my.net.req({
							data: {
								mod: 'truck_tord_real_time',
								ctr: 'settle_cancel',
								pay_type: settle_target == 'truck_owner' ? 1 : 2,
								payed_num: item.payed_num
							},
							callback: (data) => {

								//提醒
								this.$my.other.msg({
									str: '操作成功',
									type: 'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			// //撤回
			// invoice_revoke(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击确定撤回开票",
			// 		confirm:()=>{

			// 			//调用接口
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_invoice_revoke_by_admin',
			// 					payed_list:[
			// 						{
			// 							payed_num:item.payed_num,
			// 						}
			// 					],
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				}
			// 			})
			// 		}
			// 	})
			// },

			// //驳回
			// invoice_refuse(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击确定驳回开票申请",
			// 		confirm:()=>{

			// 			//调用接口
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_invoice_refuse_by_admin',
			// 					payed_list:[
			// 						{
			// 							payed_num:item.payed_num,
			// 						}
			// 					],
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				}
			// 			})
			// 		}
			// 	})
			// },

			// //确定开出
			// invoice_done(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"确定开出?",
			// 		confirm:()=>{

			// 			//调用接口
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_invoice_done_by_admin',
			// 					payed_list:[
			// 						{
			// 							payed_num:item.payed_num,
			// 						}
			// 					],
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				}
			// 			})
			// 		}
			// 	})
			// },

			//清空查询条件
			ser_para_clear() {
				this.date_value = ''
				this.form = {

					/* 支付记录相关搜索条件 */
					//各种编号
					payed_num: '', //支付编号
					truck_tord_num: '', //运单编号
					this_pay_shipper_ra_num: '', //本次支付对应的货主流水编号
					this_refund_shipper_ra_num: '', //如果退款的话退款对应的货主流水编号

					//货主相关
					shipper_tel: '', //货主手机号
					shipper_user_name: '', //货主姓名
					shipper_company_name: '', //货主公司名称

					//收款人/司机/车辆
					truck_owner_tel: '', //收款人手机号
					truck_owner_name: '', //收款人姓名
					driver_tel: '', //司机手机号
					driver_name: '', //司机姓名
					truck_plate_num: '', //车牌号

					//各种状态
					calc_mode: '', //计算方式(1:服务费率模式(以货主需支付金额为基数,在每次支付时计算服务费),2:固定金额模式(货主需支付金额和结给承运人金额之间没有逻辑关系))
					this_pay_type: '', //本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status: '', //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status: '', //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status_of_truck_owner: '', //车队长结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					settle_status_of_driver: '', //司机结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					settle_status: '',
					//支付时效相关
					creat_time: '', //此笔支付创建时间
					payed_time: '', //货主支付完成时间
					pay_refused_time: '', //此笔支付撤销时间
					invoiced_time: '', //此笔支付开票时间
					settled_time_of_truck_owner: '', //此笔支付车队长结算完成时间
					settled_time_of_driver: '', //此笔支付司机结算完成时间

					payed_time_start: '',
					payed_time_end: '',

					settled_time_start: '',
					settled_time_end: '',

					bind_bankcard_status: '',
					group_num_driver: '', //司机出款流水号
					group_num_truck_owner: '', //收款人出款流水号 
					id: '',
					out_cash_failed:'',//光大出款状态
					is_yufu:'',//是否垫资

					is_admin_pay_return:'',//是否回款
				}

				//读取
				this.get_page_data()
			},

			//更多条件
			ser_form_open() { //打开

				this.ser_form_is_show++
			},
			ser_form_sub(obj) { //提交

				//置入表单数据
				for (var key in obj) {

					if (!obj[key]) {
						continue;
					}

					this.form[key] = obj[key];

					//特殊处理
					switch (key) {

						case 'cargo_creat_time': //货源创建时间
							this.form.cargo_creat_time_arr = [
								(obj.cargo_creat_time[0].getTime() / 1000).toFixed(0),
								(obj.cargo_creat_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'creat_time': //成交时间(运单创建时间)
							this.form.creat_time_arr = [
								(obj.creat_time[0].getTime() / 1000).toFixed(0),
								(obj.creat_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'start_time': //发车时间
							this.form.start_time_arr = [
								(obj.start_time[0].getTime() / 1000).toFixed(0),
								(obj.start_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'arrived_time': //到货时间
							this.form.arrived_time_arr = [
								(obj.arrived_time[0].getTime() / 1000).toFixed(0),
								(obj.arrived_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'payed_time': //支付完成时间
							this.form.payed_time_arr = [
								(obj.payed_time[0].getTime() / 1000).toFixed(0),
								(obj.payed_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'invoiced_time': //开票完成时间
							this.form.invoiced_time_arr = [
								(obj.invoiced_time[0].getTime() / 1000).toFixed(0),
								(obj.invoiced_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'settled_time': //结算完成时间
							this.form.settled_time_arr = [
								(obj.settled_time[0].getTime() / 1000).toFixed(0),
								(obj.settled_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'tord_upld_time': //运单上报时间
							this.form.tord_upld_time_arr = [
								(obj.tord_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.tord_upld_time[1].getTime() / 1000).toFixed(0),
							];
							break;

						case 'outcash_upld_time': //出金流水上报时间
							this.form.outcash_upld_time_arr = [
								(obj.outcash_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.outcash_upld_time[1].getTime() / 1000).toFixed(0),
							];
							break;
					}
				}

				//关闭页面
				this.ser_form_is_show = 0

				//读取数据
				this.page_ser()
			},

			//搜索
			page_ser() {
				if (this.date_value) {
					this.form.payed_time_start = parseInt(this.date_value[0] / 1000);
					this.form.payed_time_end = parseInt(this.date_value[1] / 1000);
				} else {
					this.form.payed_time_start = '',
						this.form.payed_time_end = ''
				}
				if (this.settled_value) {
					this.form.settled_time_start = parseInt(this.settled_value[0] / 1000);
					this.form.settled_time_end = parseInt(this.settled_value[1] / 1000);
				} else {
					this.form.settled_time_start = '',
						this.form.settled_time_end = ''
				}
				// //读取第一页
				this.page.p = 1

				// //读取
				this.get_page_data()
			},

			//导出搜索结果
			tord_export_open() { //打开界面

				//置入条件
				this.tord_export.rull = this.form

				//打开界面
				this.tord_export.is_show++
			},
			tord_export_close() { //关闭界面

				//关闭界面
				this.tord_export.is_show = 0
			},

			//页宽变化
			page_size_change(num) {

				//置入页码
				this.page.num = num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p) {

				//置入页码
				this.page.p = p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data() {
				Loading.service(this.options);
				//加载中...
				if (this.list.loading) {
					// return;
				}

				//置为加载中
				this.list.loading = true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data: {
						mod: 'truck_tord_real_time',
						ctr: 'tord_payed_list_by_admin',
						is_get_shipper_info: true,
						is_get_shipper_company_info: true,
						is_get_truck_owner_info: true,
						is_get_driver_info: true,
						is_get_tord_info: true,
						...this.form,
						...this.page
					},
					callback: (res) => {
						this.close_loading()
						let data = res.msg
						if(res.code!=0){
							this.$my.other.msg({
								type:'info',
								str:data.error_info
							});
							return
						}
						
						//加载完毕
						this.list.loading = false

						//总数
						this.list.total = data.max

						this.total = data.total;

						//运单列表
						let tord_list = {};

						//
						let payed_num_choosed = {};

						//预处理
						for (let item of data.list) {
							//徽商银行打款状态
							if (item.api_text) {
								item.text_api = "打款中"
							}
							if (item.driver_bank_list.num == 1) {
								item.driver_bank_list_info = item.driver_bank_list.list[0]
							}
							if (item.truck_owner_bank_list.num == 1) {
								item.truck_owner_bank_list_info = item.truck_owner_bank_list.list[0]
							}
							//支付时间
							item.creat_time_text = this.$my.other.totime(item.creat_time);
							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch (item.this_pay_type) {
								case '1':
									item.this_pay_type_text = "现金";
									break;
								case '2':
									item.this_pay_type_text = "燃油费";
									break;
								case '3':
									item.this_pay_type_text = "过路费";
									break;
								case '4':
									item.this_pay_type_text = "垫资支付";
									break;
							}

							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch (item.pay_status) {
								case '1':
									item.pay_status_text = "未支付";
									break;
								case '2':
									item.pay_status_text = "支付中";
									break;
								case '3':
									item.pay_status_text = `支付成功 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '4':
									item.pay_status_text = `支付失败 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '5':
									item.pay_status_text = `支付撤销 ${this.$my.other.totime(item.pay_refused_time)}`;
									break;
							}
							var invoiced_time = ''
							if (item.invoiced_time!=0) {
								// set_time = ''
								invoiced_time = this.$my.other.totime(item.invoiced_time)
							} else {
								invoiced_time =''
							}
							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch (item.invoice_status) {
								case '1':
									item.invoice_status_text = "未申请";
									break;
								case '2':
									item.invoice_status_text = "申请中";
									break;
								case '3':
									item.invoice_status_text =
										`开票成功 ${invoiced_time}`;
									break;
								case '4':
									item.invoice_status_text =
										`开票失败 ${invoiced_time}`;
									break;
							}
							var set_time = ''
							if (item.settled_time_of_truck_owner!=0) {
								// set_time = ''
								set_time = this.$my.other.totime(item.settled_time_of_truck_owner)
							} else {

							}
							//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch (item.settle_status_of_truck_owner) {
								case '1':
									item.settle_status_of_truck_owner_text = "未申请";
									break;
								case '2':
									item.settle_status_of_truck_owner_text = "待打款";
									break;
								case '3':
									item.settle_status_of_truck_owner_text = `打款成功 ${set_time}`;
									break;
								case '4':
									item.settle_status_of_truck_owner_text = `打款失败 ${set_time}`;
									break;
							}
							var time = ''
							if (item.settled_time_of_driver>0) {
								// time = ''
								time = this.$my.other.totime(item.settled_time_of_driver)
							} else {}
							//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch (item.settle_status_of_driver) {
								case '1':
									item.settle_status_of_driver_text = "未申请";
									break;
								case '2':
									item.settle_status_of_driver_text = "待打款";
									break;
								case '3':
									item.settle_status_of_driver_text = `打款成功 ${time}`;
									break;
								case '4':
									item.settle_status_of_driver_text = `打款失败 ${time}`;
									break;
							}

							//本次支付
							item.this_payed_text = item.this_payed + "元"

							//计算本次结给承运人的全部金额
							item.this_settled = parseFloat(item.this_settled_of_truck_owner) + parseFloat(item
								.this_settled_of_driver)

							//服务费率模式: 显示服务费 显示结给司机的钱
							if (item.calc_mode == 1) {

								//服务费
								item.service_charge_text = (item.this_payed - item.this_settled).toFixed(2) +
									"元"

								//结给司机的钱
								item.this_settled_text = item.this_settled + "元"

								//固定金额模式
							} else if (item.calc_mode == 2) {

								//服务费
								item.service_charge_text = "--"

								//结给司机的钱
								item.this_settled_text = "--"
							}

							//装货联系人
							if (item.tord_info.case_link_man) {
								item.tord_info.case_link_man_text = item.tord_info.case_link_man;
							} else item.tord_info.case_link_man_text = "无";

							//装货联系电话
							if (item.tord_info.case_link_tel) {
								item.tord_info.case_link_tel_text = item.tord_info.case_link_tel;
							} else item.tord_info.case_link_tel_text = "无";

							//卸货联系人
							if (item.tord_info.aim_link_man) {
								item.tord_info.aim_link_man_text = item.tord_info.aim_link_man;
							} else item.tord_info.aim_link_man_text = "无";

							//卸货联系电话
							if (item.tord_info.aim_link_tel) {
								item.tord_info.aim_link_tel_text = item.tord_info.aim_link_tel;
							} else item.tord_info.aim_link_tel_text = "无";

							/* 装货地其他数据(json) */
							item.tord_info.case_other_obj = JSON.parse(item.tord_info.case_other);

							//装货时间
							if (!item.tord_info.case_other_obj.case_time_start && item.tord_info.case_other_obj
								.case_time_end) { //只有止点

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_end) + "以前";

							} else if (item.tord_info.case_other_obj.case_time_start && !item.tord_info
								.case_other_obj.case_time_end) { //只有起点

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_start) + "以后";

							} else if (item.tord_info.case_other_obj.case_time_start && item.tord_info
								.case_other_obj.case_time_end) { //都有

								item.tord_info.case_other_obj.case_date = this.$my.other.todate(item.tord_info
									.case_other_obj.case_time_start) + " ~ " + this.$my.other.todate(item
									.tord_info.case_other_obj.case_time_end);

							} else if (!item.tord_info.case_other_obj.case_time_start && !item.tord_info
								.case_other_obj.case_time_end) { //都没有

								item.tord_info.case_other_obj.case_date = "未设置";
							}

							/* 卸货地其他数据(json) */
							item.tord_info.aim_other_obj = JSON.parse(item.tord_info.aim_other);

							//卸货时间
							if (!item.tord_info.aim_other_obj.aim_time_start && item.tord_info.aim_other_obj
								.aim_time_end) { //只有止点

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_end) + "以前";

							} else if (item.tord_info.aim_other_obj.aim_time_start && !item.tord_info
								.aim_other_obj.aim_time_end) { //只有起点

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_start) + "以后";

							} else if (item.tord_info.aim_other_obj.aim_time_start && item.tord_info
								.aim_other_obj.aim_time_end) { //都有

								item.tord_info.aim_other_obj.aim_date = this.$my.other.todate(item.tord_info
									.aim_other_obj.aim_time_start) + " ~ " + this.$my.other.todate(item
									.tord_info.aim_other_obj.aim_time_end);

							} else if (!item.tord_info.aim_other_obj.aim_time_start && !item.tord_info
								.aim_other_obj.aim_time_end) { //都没有

								item.tord_info.aim_other_obj.aim_date = "未设置";
							}

							//运单状态
							switch (item.tord_info.status) {
								case '1':
									item.tord_info.status_text = "待发车";
									break;
								case '2':
									item.tord_info.status_text = "运输中";
									break;
								case '3':
									item.tord_info.status_text = "已到货";
									break;
							}

							//结算方式
							switch (item.tord_info.settlement_type) {
								case '1':
									item.tord_info.settlement_type_text = "按吨数";
									break;
								case '2':
									item.tord_info.settlement_type_text = "按方数";
									break;
								case '3':
									item.tord_info.settlement_type_text = "按趟";
									break;
							}

							//是否回程
							switch (item.tord_info.is_need_return) {
								case '1':
									item.tord_info.is_need_return_text = "需要回程";
									break;
								case '2':
									item.tord_info.is_need_return_text = "不需要回程";
									break;
							}
							//光大状态
							if(item.out_cash_type == 4){
								item.out_cash_type_text = '光大出款'
							}
							if(item.out_cash_num){
								item.out_cash_num_text = '已发起打款'
							}else{
								item.out_cash_num_text = '光大分账中'
							}
							//汇总运单数据
							if (!tord_list[item.tord_info.id]) {
								tord_list[item.tord_info.id] = JSON.parse(JSON.stringify(item.tord_info))
								tord_list[item.tord_info.id].cost_info = JSON.parse(JSON.stringify(item
									.cost_info))
								tord_list[item.tord_info.id].driver_info = JSON.parse(JSON.stringify(item
									.driver_info))
								tord_list[item.tord_info.id].shipper_info = JSON.parse(JSON.stringify(item
									.shipper_info))
								tord_list[item.tord_info.id].shipper_company_info = JSON.parse(JSON.stringify(
									item.shipper_company_info))
								tord_list[item.tord_info.id].truck_owner_info = JSON.parse(JSON.stringify(item
									.truck_owner_info))
								tord_list[item.tord_info.id].payed_list = [];
							}
							//缓存运单id
							var tord_id = item.tord_info.id
							tord_list[tord_id].creat_time_text = this.$my.other.totime(item.tord_info
								.creat_time);
							//删除一些数据
							delete(item.tord_info);
							delete(item.cost_info);
							delete(item.driver_info);
							delete(item.shipper_info);
							delete(item.shipper_company_info);
							delete(item.truck_owner_info);
							delete(item.truck_info);
							//置入支付记录数据
							tord_list[tord_id].payed_list.push(JSON.parse(JSON.stringify(item)))

							//置入
							payed_num_choosed[item.payed_num] = {
								id:item.id,
								checked: false,
								payed_num: item.payed_num,
								truck_tord_num: item.truck_tord_num,
								pay_status: item.pay_status,
								invoice_status: item.invoice_status,
								settle_status_of_truck_owner: item.settle_status_of_truck_owner,
								settle_status_of_driver: item.settle_status_of_driver,
								this_settled: item.this_settled,
								this_settled_text: item.this_settled + "元",
								driver_bank_list: item.driver_bank_list,
								truck_owner_bank_list: item.truck_owner_bank_list
							}

							this.list_data_idx[item.payed_num] = item;
						}
						//渲染
						this.list.data = Object.values(tord_list).reverse()

						//缓存支付编号数据
						this.payed_num_choosed = payed_num_choosed;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		background-color: #fff;
		color: #666;
		text-align: left;
		height: calc(100% - 140px);
		font-size: 12px;
		border-bottom: 1px solid #ccc;

		.thead {
			border-bottom: 1px solid #ccc;
		}

		.tbody {
			height: calc(100% - 41px);
			overflow-y: auto;

			.tr {
				padding: 10px;
				border: 1px solid #eee;
				margin: 10px;

				.tord_info {
					display: flex;
					padding: 4px;
					background-color: #eee;
					justify-content: space-between;

					.item {
						margin: 3px;
					}
				}

				.payed_list {
					margin-top: 6px;

					.ptr {
						display: flex;

						.ptd {
							padding: 3px;
							margin: 3px;

							.btn {
								cursor: pointer;
								display: inline-block;
								padding: 0 2px;
							}

							.blue {
								color: #409EFF;
							}

							.red {
								margin-bottom: 5px;
								color: red;
							}
						}
					}
				}
			}
		}
	}

	#haed {
		text-decoration: none;
		color: rgb(0, 174, 255);
	}

	.page {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}

	.mark {

		.btn {
			width: 150px;
			overflow: hidden; //超出的文本隐藏
			text-overflow: ellipsis; //溢出用省略号显示
			white-space: nowrap; // 默认不换行；
		}

		.btn:hover {
			text-overflow: inherit;
			overflow: visible;
			white-space: pre-line;
			/*合并空白符序列，但是保留换行符。*/
		}

	}

	.ser_form {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.el_form_item {
		width: 16.6672%;
	}

	.el_input {
		width: 94%
	}

	.btn_left {
		margin: 0;
	}

	.total_info {
		text-align: right;
		font-size: 15px;
		margin-bottom: 10px;
		margin-right: 1px;
		color: #606266;

		span {
			margin-left: 10px;
		}
	}
</style>